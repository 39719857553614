import InfoIcon from "@mui/icons-material/Info";
import {
  Autocomplete,
  Box,
  InputAdornment,
  Link,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { colors } from "constants/colors";
import { conditions } from "constants/conditions";
import { enrolledStatuses } from "constants/enrolled_statuses";
import { securityLevels } from "constants/security_levels";
import { Form, useFormikContext } from "formik";
import { Dispatch, SetStateAction } from "react";
import { Inventory } from "types/inventory";
import { keyboardLanguageDropdownOptions } from "types/inventory";

interface ITechForm {
  completed: { [k: string]: boolean };
  setCompleted: Dispatch<SetStateAction<{ [k: string]: boolean }>>;
  disabled: boolean;
}

const tooltipIcon = <InfoIcon fontSize="inherit" color="info" sx={{ ml: 0.5 }} />;

export default function TechForm({ completed, setCompleted, disabled }: ITechForm) {
  const { handleChange, values, getFieldProps, errors, touched, setFieldValue } = useFormikContext<Inventory>();
  const handleBlur = (event: any) => {
    const newCompleted = { ...completed };
    if (event.target.value !== "") {
      newCompleted[event.target.name] = true;
      setCompleted(newCompleted);
    }
  };

  return (
    <Box>
      <Form onSelect={handleChange}>
        <Stack spacing={3}>
          <Stack direction="row" gap={4}>
            <Autocomplete
              autoHighlight
              disableClearable
              disablePortal
              fullWidth
              openOnFocus
              size="small"
              disabled={disabled}
              onBlur={handleBlur}
              isOptionEqualToValue={(option, value) => option !== value}
              options={conditions}
              onChange={(_e, value) => setFieldValue("condition", value || "")}
              value={values.condition}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="condition"
                  label={
                    <>
                      Cosmetic Grade
                      <Tooltip
                        title={`A cumulative grade of dents, dings, scratches, visible wear, laminate damage and worn keys. From "Near Perfect" A to "Severely Damaged" D. This is not an indicator of the unit's functionality.`}
                        placement="right"
                      >
                        {tooltipIcon}
                      </Tooltip>
                    </>
                  }
                  error={Boolean(touched.condition && errors.condition)}
                  helperText={touched.condition && errors.condition}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
            <Autocomplete
              autoHighlight
              disableClearable
              disablePortal
              fullWidth
              openOnFocus
              size="small"
              disabled={disabled}
              onBlur={handleBlur}
              isOptionEqualToValue={(option, value) => option !== value}
              options={colors}
              onChange={(_e, value) => setFieldValue("color", value || "")}
              value={values.color}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="color"
                  label={
                    <>
                      Color
                      <Tooltip
                        title={
                          "Overall color of the device case. If multiple colors are present, select the most dominant color."
                        }
                        placement="right"
                        sx={{ ml: 1 }}
                      >
                        {tooltipIcon}
                      </Tooltip>
                    </>
                  }
                  error={Boolean(touched.color && errors.color)}
                  helperText={touched.color && errors.color}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Stack>

          <Stack direction="row" gap={4}>
            <Autocomplete
              autoHighlight
              disableClearable
              disablePortal
              fullWidth
              openOnFocus
              size="small"
              disabled={disabled}
              onBlur={handleBlur}
              isOptionEqualToValue={(option, value) => option !== value}
              options={securityLevels}
              onChange={(_e, value) => setFieldValue("securityLevel", value || "")}
              value={values.securityLevel}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="securityLevel"
                  label={
                    <>
                      Security State
                      <Tooltip
                        title={
                          "Hardware and Software locks of all types. This is separate from mobile device management."
                        }
                        placement="right"
                        sx={{ ml: 1 }}
                      >
                        {tooltipIcon}
                      </Tooltip>
                    </>
                  }
                  error={Boolean(touched.securityLevel && errors.securityLevel)}
                  helperText={touched.securityLevel && errors.securityLevel}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />

            <Autocomplete
              autoHighlight
              disableClearable
              disablePortal
              fullWidth
              openOnFocus
              size="small"
              disabled={disabled}
              onBlur={handleBlur}
              isOptionEqualToValue={(option, value) => option !== value}
              options={enrolledStatuses}
              onChange={(_e, value) => setFieldValue("enrolledStatus", value || "")}
              value={values.enrolledStatus}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="enrolledStatus"
                  label={
                    <>
                      Enrolled Status
                      <Tooltip
                        title={
                          "State of the device enrollment. Enrollment status is refreshed when the computer connects to the internet for the first time after a clean OS installation."
                        }
                        placement="right"
                        sx={{ ml: 1 }}
                      >
                        {tooltipIcon}
                      </Tooltip>
                    </>
                  }
                  error={Boolean(touched.enrolledStatus && errors.enrolledStatus)}
                  helperText={touched.enrolledStatus && errors.enrolledStatus}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Stack>

          <Stack direction="row" gap={4}>
            <TextField
              fullWidth
              type="text"
              size="small"
              variant="outlined"
              {...getFieldProps("batteryCount")}
              value={getFieldProps("batteryCount").value ?? ""}
              onBlur={handleBlur}
              disabled={disabled}
              label={
                <>
                  Battery Cycle Count
                  <Tooltip
                    title={
                      "Cycle Count is the amount of times the battery has been through a full power cycle. Use 0 as the default value if you are unable to record the number."
                    }
                    placement="right"
                    sx={{ ml: 1 }}
                  >
                    {tooltipIcon}
                  </Tooltip>
                </>
              }
              error={Boolean(touched.batteryCount && errors.batteryCount)}
              helperText={touched.batteryCount && errors.batteryCount}
              InputLabelProps={{ shrink: true }}
            />

            <TextField
              fullWidth
              type="text"
              size="small"
              variant="outlined"
              {...getFieldProps("batteryHealth")}
              value={getFieldProps("batteryHealth").value ?? ""}
              onBlur={handleBlur}
              disabled={disabled}
              label={
                <>
                  Battery Health
                  <Tooltip
                    title={
                      "Battery health is the calculated percentage of the battery's charge capacity. The formula follows: Current capacity divided by the Design capacity, out of 100%."
                    }
                    placement="right"
                    sx={{ ml: 1 }}
                  >
                    {tooltipIcon}
                  </Tooltip>
                </>
              }
              error={Boolean(touched.batteryHealth && errors.batteryHealth)}
              helperText={touched.batteryHealth && errors.batteryHealth}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              InputLabelProps={{ shrink: true }}
            />
          </Stack>

          <TextField
            select
            fullWidth
            size="small"
            disabled={disabled}
            onBlur={handleBlur}
            onChange={(e) => setFieldValue("keyboardLanguage", e.target.value)}
            value={values.keyboardLanguage || "USA"}
            name="keyboardLanguage"
            InputLabelProps={{ shrink: true }}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: { maxHeight: 48 * 4.5 + 8 },
                },
              },
            }}
            label={
              <>
                Keyboard Language
                <Tooltip
                  title={
                    <>
                      The language of the keyboard.<br></br>
                      <Link color="secondary" href="https://support.apple.com/en-us/102743" target="_blank">
                        How to identify your Apple keyboard layout by country or region
                      </Link>
                    </>
                  }
                  placement="right"
                  sx={{ ml: 1 }}
                >
                  {tooltipIcon}
                </Tooltip>
              </>
            }
            error={Boolean(touched.keyboardLanguage && errors.keyboardLanguage)}
            helperText={touched.keyboardLanguage && errors.keyboardLanguage}
          >
            {keyboardLanguageDropdownOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            multiline
            minRows={2}
            maxRows={4}
            size="small"
            variant="outlined"
            label="Comments"
            disabled={disabled}
            {...getFieldProps("comments")}
            onBlur={handleBlur}
            value={getFieldProps("comments").value ?? ""}
            InputLabelProps={{ shrink: true }}
          />
        </Stack>
      </Form>
    </Box>
  );
}
