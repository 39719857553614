import "./assets/App.css";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem as MuiListItem,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText as MuiListItemText,
  ListItemTextProps,
  styled,
  Typography,
} from "@mui/material";
import {
  AccountBalance,
  ExpandLess,
  ExpandMore,
  HomeOutlined,
  LocalShippingOutlined,
  PowerSettingsNew,
  SupportAgent,
  ShoppingCartCheckout,
  VolunteerActivism as VolunteerActivismIcon,
  Store,
} from "@mui/icons-material";
import { userHasPermission } from "utils/userPermissions";
import TokenService from "services/token.service";
import useAuth from "hooks/useAuth";
import { useFeatureFlag } from "hooks/useFeatureFlag";

const ListItem = styled(MuiListItem)<ListItemButtonProps>(({ theme }) => ({
  "&.Mui-selected": { borderRight: `4px solid ${theme.palette.primary.main}` },
})) as unknown as typeof MuiListItem;

const ListItemText = styled(MuiListItemText)<ListItemTextProps>(() => ({
  ".MuiTypography-root": { fontWeight: "bold" },
}));

interface ListItemCollapseProps {
  label: React.ReactNode;
  icon: React.ReactNode;
  items: {
    label: React.ReactNode;
    to: string;
    hidden?: boolean;
  }[];

  onClick(): void;

  open: boolean;
}

function ListItemCollapse({ onClick, open, label, icon, items }: ListItemCollapseProps) {
  const { pathname } = useLocation();

  let selectedItemIndex = -1;
  items.forEach((item, i) => {
    let regex = /\/[a-zA-Z\-]*/; // 1 resource
    if (item.to.split("/").length == 3) {
      regex = /\/[a-zA-Z\-]*\/[a-zA-Z\-]*/; // 2 resources
    }
    const [match] = item.to.match(regex) ?? [];
    const isSelected = !!(match && pathname.startsWith(match));
    if (isSelected) {
      selectedItemIndex = i;
    }
  });
  const hasSelectedItem = selectedItemIndex > -1;

  const [isOpen, setIsOpen] = useState(() => hasSelectedItem);

  useEffect(() => {
    if (!open) {
      setIsOpen(false);
    }
  }, [open]);

  useEffect(() => {
    if (hasSelectedItem) {
      setIsOpen(true);
    }
  }, [hasSelectedItem]);

  return (
    <>
      <ListItem
        onClick={() => {
          setIsOpen(!isOpen);
          onClick();
        }}
        button
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={label} />
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <Box
          sx={{
            my: 1,
            ml: 4,
            pt: 1,
            px: 2,
            borderLeft: `2px solid #BDBDBD`,
            ".MuiButtonBase-root": {
              borderRadius: "4px",
              color: "text.secondary",
              ".MuiTypography-root": { fontWeight: "normal" },
              "&.Mui-selected": { borderRight: `0` },
            },
          }}
        >
          <List component="div" disablePadding>
            {items.map((item, i) => {
              const isSelected = selectedItemIndex === i;
              if (item.hidden) return null;
              return (
                <ListItem key={i} onClick={onClick} selected={isSelected} button component={Link} to={item.to}>
                  <ListItemText primary={item.label} />
                </ListItem>
              );
            })}
          </List>
        </Box>
      </Collapse>
    </>
  );
}

const logout = () => {
  TokenService.remove("accessToken");
  window.location.href = "/login";
};

const useLogoutConfirmation = () => {
  const [windowOpenState, setWindowOpenState] = useState(false);

  const handleOpenLogoutConfirmationWindow = () => {
    setWindowOpenState(true);
  };

  const handleCloseLogoutConfirmationWindow = () => {
    setWindowOpenState(false);
  };

  function LogoutConfirmationDialog() {
    return (
      <Dialog open={windowOpenState} onClose={handleCloseLogoutConfirmationWindow} fullWidth>
        <DialogContent>
          <Typography variant="body1">Are you sure you want to log out?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseLogoutConfirmationWindow}>Cancel</Button>
          <Button onClick={logout}>Log Out</Button>
        </DialogActions>
      </Dialog>
    );
  }

  return { LogoutConfirmationDialog, handleOpenLogoutConfirmationWindow };
};

interface ListItemsProps {
  open: boolean;

  setOpen(v: boolean): void;

  currentUserPermissions: string[];
}

export function ListItems({ open, setOpen, currentUserPermissions }: ListItemsProps) {
  const { loggedIn } = useAuth();
  const { LogoutConfirmationDialog, handleOpenLogoutConfirmationWindow } = useLogoutConfirmation();
  const { enabled: isElasticInventories } = useFeatureFlag("elastic_inventories");

  return (
    <List component={Box}>
      {userHasPermission("warehouse", currentUserPermissions) && (
        <ListItemCollapse
          label={"Warehouse"}
          icon={<HomeOutlined color="primary" />}
          open={open}
          onClick={() => {
            setOpen(true);
          }}
          items={[
            {
              label: "Pickups",
              to: "/pickups",
            },
            {
              label: "Inventory",
              to: isElasticInventories
                ? "/inventories?q=state%3A%28in_process+OR+resellable%29"
                : "/inventories?filter%5B%5D=state%2Cis_any_of%2Cin_process%2Cresellable",
            },
            {
              label: "Inventory Templates",
              to: "/inventory-templates",
            },
            {
              label: "Inventory Uploads",
              to: "/inventory-uploads",
            },
            {
              label: "Tech",
              to: "/technician",
            },
            {
              label: "Move Item",
              to: "/scan",
            },
            {
              label: "Outbound",
              to: "/outbound",
            },
            {
              label: "Outbound Shipping",
              to: "/asset_retrievals?asset_retrieval_status=open",
            },
            {
              label: "Drivers",
              to: "/drivers",
            },
            {
              label: "Scan & Discover",
              to: "/scan-and-discover",
            },
            {
              label: "Inbound Shipping",
              to: "/virtual-pickup?virtual_pending_processing=true",
            },
          ]}
        />
      )}
      {userHasPermission("warehouse", currentUserPermissions) && (
        <ListItemCollapse
          label={"Fulfillment"}
          icon={<ShoppingCartCheckout color="primary" />}
          open={open}
          onClick={() => setOpen(true)}
          items={[
            {
              label: "Picklist",
              to: "/picklist",
            },
            {
              label: "Order Fulfillment",
              to: "/fulfillment?sort=-age",
            },
            {
              label: "Batches",
              to: "/batches?sort=-age",
            },
          ]}
        />
      )}
      {userHasPermission("partner_warehouse", currentUserPermissions) &&
        !userHasPermission("warehouse", currentUserPermissions) && (
          <ListItemCollapse
            label={"Warehouse"}
            icon={<HomeOutlined color="primary" />}
            open={open}
            onClick={() => {
              setOpen(true);
            }}
            items={[
              {
                label: "Pickups",
                to: "/pickups",
              },
              {
                label: "Inventory",
                to: "/inventories?filter%5B%5D=state%2Cis_any_of%2Cin_process%2Cresellable",
              },
              {
                label: "Inventory Templates",
                to: "/inventory-templates",
              },
              {
                label: "Inventory Uploads",
                to: "/inventory-uploads",
              },
              {
                label: "Tech",
                to: "/technician",
              },
              {
                label: "Move Item",
                to: "/scan",
              },
              {
                label: "Outbound",
                to: "/outbound",
              },
              {
                label: "Scan & Discover",
                to: "/scan-and-discover",
              },
            ]}
          />
        )}

      {userHasPermission("logistics", currentUserPermissions) && (
        <ListItemCollapse
          label={"Logistics"}
          icon={<LocalShippingOutlined color="primary" />}
          open={open}
          onClick={() => setOpen(true)}
          items={[
            {
              label: "Pickups",
              to: "/logistics?logistics_status=scheduled",
            },
            // {
            //   label: "Pickup Timeslots",
            //   to: "/logistics/pickup-timeslots",
            // },
            {
              label: "Partners",
              to: "/logistics/partners",
            },
            {
              label: "Clients",
              to: "/logistics/clients",
            },
          ]}
        />
      )}

      {userHasPermission("finance", currentUserPermissions) && (
        <ListItemCollapse
          label={"Finance"}
          icon={<AccountBalance color="primary" />}
          open={open}
          onClick={() => setOpen(true)}
          items={[
            {
              label: "Pickups",
              to: "/finance/pickups",
            },
            {
              label: "Clients",
              to: "/finance/clients",
            },
          ]}
        />
      )}

      {userHasPermission("tech_access", currentUserPermissions) && (
        <ListItemCollapse
          label={"Tech Access"}
          icon={<VolunteerActivismIcon color="primary" />}
          open={open}
          onClick={() => setOpen(true)}
          items={[
            {
              label: "Donation Requests",
              to: "/donation-requests",
            },
            {
              label: "Benefiting Organizations",
              to: "/benefiting-organization",
            },
          ]}
        />
      )}
      {userHasPermission("support", currentUserPermissions) && (
        <ListItemCollapse
          label={"Support"}
          icon={<SupportAgent color="primary" />}
          open={open}
          onClick={() => setOpen(true)}
          items={[
            {
              label: "Clients",
              to: "/support/clients",
            },
          ]}
        />
      )}
      {userHasPermission("resale", currentUserPermissions) && (
        <ListItemCollapse
          label={"Resale"}
          icon={<Store color="primary" />}
          open={open}
          onClick={() => setOpen(true)}
          items={[
            {
              label: "Product Catalogs",
              to: "/product_catalogs",
            },
            {
              label: "Upload Stock Photos",
              to: "/stock-photos",
            },
            {
              label: "Listings",
              to: "/listings",
            },
          ]}
        />
      )}
      <LogoutConfirmationDialog />
      {loggedIn && (
        <ListItem button onClick={handleOpenLogoutConfirmationWindow}>
          <ListItemIcon>
            <PowerSettingsNew color="primary" />
          </ListItemIcon>
          <ListItemText primary="Log Out" />
        </ListItem>
      )}
    </List>
  );
}
