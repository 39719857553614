import { Alert, Stack, Typography } from "@mui/material";
import { useIsLocal } from "hooks/useIsLocalhost";

export function NoRowsElasticOverlay() {
  const isLocal = useIsLocal();
  return (
    <Stack alignItems="center" direction="column" mt={2} sx={{ height: "100%", placeContent: "center" }}>
      {isLocal ? (
        <Alert severity="info">
          No rows found. Run <code>rails es:import_all</code> to import data into elasticsearch.
        </Alert>
      ) : (
        <Typography>No rows</Typography>
      )}
    </Stack>
  );
}
