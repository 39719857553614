import * as React from "react";
import { cloneElement, useRef, useState } from "react";
import { useGridApiContext, useGridApiEventHandler } from "@mui/x-data-grid-pro";
import { useEventListener } from "hooks/useEventListener";
import { Button, Menu, MenuList } from "@mui/material";
import { Checklist } from "@mui/icons-material";
import { RevivnToolbarProps } from "components/DataGrid/components/RevivnToolbar";

export const GridBulkActionsButton = (props: RevivnToolbarProps) => {
  const [enableBulkActions, setEnableBulkActions] = useState(false);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const apiRef = useGridApiContext();
  useGridApiEventHandler(apiRef, "selectionChange", (selectionModel) => {
    setEnableBulkActions(selectionModel.length > 0);
  });
  useEventListener("bulkActionComplete", () => {
    apiRef.current.setSelectionModel([]);
    props.onBulkActionComplete?.();
  });

  return (
    <>
      <Button
        size={"small"}
        ref={anchorRef}
        disabled={!enableBulkActions}
        startIcon={<Checklist />}
        onClick={() => {
          setOpen((prevOpen) => !prevOpen);
        }}
      >
        Bulk Actions
      </Button>
      <Menu anchorEl={anchorRef.current} open={open} onClose={() => setOpen(false)}>
        <MenuList sx={{ p: 0 }} onClick={() => setOpen(false)}>
          {props.getBulkActions
            ? props.getBulkActions().map((action, index) => cloneElement(action, { key: index }))
            : "No bulk actions specified"}
        </MenuList>
      </Menu>
    </>
  );
};
