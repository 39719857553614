import { BulkDeleteAction } from "components/Inventories/DataGrid/BulkDeleteAction";
import { BulkPrintAction } from "components/Inventories/DataGrid/BulkPrintAction";

export const BulkActions = () => {
  return (
    <>
      <BulkDeleteAction />
      <BulkPrintAction />
    </>
  );
};
