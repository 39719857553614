import useSWR from "swr";
import { CollectionLike, fetcher } from "services/api.service";
import { useEffect, useMemo } from "react";
import {
  DataGridPro,
  GridNoRowsOverlay,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { LinearProgress } from "@mui/material";
import { ResponseWithMeta } from "types/paginatedResponse";
import { DataGridProProps } from "@mui/x-data-grid-pro/models/dataGridProProps";
import { ServerErrorOverlay } from "components/DataGrid/components/ServerErrorOverlay";
import { useLazyColumns } from "components/DataGrid/hooks/useLazyColumns";
import { useSearchParam } from "hooks/useSearchParam";
import { useSearchParamArray } from "hooks/useSearchParamArray";
import { deserializeFilterModel, deserializeSortModel } from "components/DataGrid/helpers/deserializers";
import { serializeFilterModel, serializeSortModel } from "components/DataGrid/helpers/serializers";
import { RevivnApiForm } from "components/Form/RevivnApiForm";
import { useAlert } from "hooks/useAlert";
import { SubmitAwareButton } from "components/Form/SubmitAwareButton";
import { SystemUpdateAlt } from "@mui/icons-material";

interface RevivnApiDataGridProProps extends Partial<DataGridProProps> {
  url: string;
  remoteExport?: boolean;
  filtersStorageName?: string;
}

function extractCollectionKey(data: ResponseWithMeta) {
  const collectionKey = Object.keys(data).find((key) => key !== "meta");
  if (!collectionKey) throw new Error("Unable to extract collection key from response");
  return collectionKey;
}

const DEFAULT_PAGE_SIZE = 25;

function RemoteDataExport({ url }: { url: string }) {
  const { alertSuccess } = useAlert();
  const onSuccess = () => {
    alertSuccess("Request received we'll email CSV with the data.");
  };

  return (
    <RevivnApiForm initialValues={{}} action={url} method="GET" onSuccess={onSuccess}>
      <SubmitAwareButton type="submit" startIcon={<SystemUpdateAlt />} color="primary" sx={{ ml: 2 }}>
        Export
      </SubmitAwareButton>
    </RevivnApiForm>
  );
}
function RevivnToolbar({ url }: { url?: string }) {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      {url && <RemoteDataExport url={url} />}
      {!url && <GridToolbarExport />}
    </GridToolbarContainer>
  );
}

export const RevivnApiDataGrid = (props: RevivnApiDataGridProProps) => {
  const [pageSize, setPageSize] = useSearchParam("page[size]", DEFAULT_PAGE_SIZE, Number);
  const [page, setPage] = useSearchParam("page[number]", 1, Number);
  const [sort, setSort] = useSearchParam("sort", "", String);
  const [filters, setFilters] = useSearchParamArray("filter[]", String);
  const [filterLinkOperator, setFilterLinkOperator] = useSearchParam("filter_link_operator", "AND", String);

  const resourcesExportUrl = useMemo(() => {
    if (props.url.includes("?")) {
      return props.url.split("?")[0] + "/export_csv?" + props.url.split("?")[1] + "&" + window.location.search.slice(1);
    } else {
      return props.url + "/export_csv" + window.location.search;
    }
  }, [props.url]);

  const { data, isLoading, error } = useSWR<CollectionLike<unknown>>(() => {
    if (props.url.includes("?")) {
      return props.url + "&" + window.location.search.slice(1);
    } else {
      return props.url + window.location.search;
    }
  }, fetcher);

  const rows = useMemo(() => (data ? data[extractCollectionKey(data)] : []), [data]);
  const lazyColumns = useLazyColumns(rows);
  const columns = props.columns || lazyColumns;
  const NoRowsOverlay = error ? ServerErrorOverlay : GridNoRowsOverlay;
  const totalCount = data?.meta.count || 0;

  const storedFilters = props.filtersStorageName && JSON.parse(localStorage.getItem(props.filtersStorageName) || "[]");
  const storedFilterOperator =
    (props.filtersStorageName && localStorage.getItem(`${props.filtersStorageName}Operator`)) || "";

  useEffect(() => {
    if (props.filtersStorageName) {
      setFilters(storedFilters);
      if (storedFilterOperator) {
        setFilterLinkOperator(storedFilterOperator);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DataGridPro
      autoHeight
      sx={{ background: "background.paper" }}
      rows={rows}
      rowCount={totalCount}
      columns={columns}
      loading={isLoading}
      initialState={{
        sorting: { sortModel: deserializeSortModel(sort) },
        filter: {
          filterModel: deserializeFilterModel(storedFilters || filters, storedFilterOperator || filterLinkOperator),
        },
        pagination: { pageSize },
      }}
      pagination={true}
      paginationMode="server"
      page={page - 1}
      onPageChange={(newPage) => setPage(newPage + 1)}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      rowsPerPageOptions={[5, 25, 50, 100]}
      filterMode="server"
      onFilterModelChange={(model) => {
        const serializedFilters = serializeFilterModel(model);
        setFilters(serializedFilters);
        setPage(1);
        if (props.filtersStorageName) {
          localStorage.setItem(props.filtersStorageName, JSON.stringify(serializedFilters));
          localStorage.setItem(`${props.filtersStorageName}Operator`, model.linkOperator?.toUpperCase() || "AND");
        }
        if (model.items.length > 1 && model.linkOperator) setFilterLinkOperator(model.linkOperator.toUpperCase());
      }}
      sortingMode="server"
      onSortModelChange={(model) => {
        setPage(1);
        setSort(serializeSortModel(model));
      }}
      components={{
        LoadingOverlay: LinearProgress,
        Toolbar: () => <RevivnToolbar url={(props.remoteExport && resourcesExportUrl) || undefined} />,
        NoRowsOverlay,
      }}
      componentsProps={{
        noRowsOverlay: { error },
      }}
      {...props}
    />
  );
};
