import { GridColType, GridLinkOperator } from "@mui/x-data-grid-pro";
import { GridFilterModel, GridSortModel } from "@mui/x-data-grid";
import { camelCase } from "lodash";
import { operators } from "components/DataGrid/helpers/operators";
import { DataGridProProps } from "@mui/x-data-grid-pro/models/dataGridProProps";

function postProcessValue(value: string | undefined, escapeWhitespace: boolean): string | undefined {
  if (!value) return;
  // Unescape whitespace if it was escaped during serialization
  const unescapedValue = value.replaceAll(/\\(?=[+\-=&|><!(){}\[\]^"~*?:\\/])/g, "");
  if (escapeWhitespace) return unescapedValue.replaceAll(/\\ /g, " ");
  return unescapedValue;
}

export function elasticSortStringToSortModel(sortString: string): GridSortModel {
  if (sortString === "") return [];
  return sortString.split(",").map((col) => {
    const direction = col.startsWith("-") ? "desc" : "asc";
    const field = direction === "desc" ? col.slice(1) : col;
    return { field: camelCase(field), sort: direction };
  });
}

type Value = string | undefined;

function getOperatorAndValue(value: string, columnType: GridColType): [string, Value | Value[]] {
  for (const { operatorValue, matcher, columnTypes, escapeWhitespace } of operators) {
    if ((columnTypes && !columnTypes.includes(columnType)) || !columnTypes) continue;
    if (matcher.global) {
      const matches = value.matchAll(matcher);
      if (matches) {
        return [operatorValue, Array.from(matches, (m) => postProcessValue(m[0], escapeWhitespace))];
      }
    } else {
      const match = value.match(matcher);
      if (match) return [operatorValue, postProcessValue(match[1], escapeWhitespace)];
    }
  }
  throw new Error(`Unknown operator: ${value}, columnType: ${columnType}`);
}

function getFieldColumnType(columns: DataGridProProps["columns"], field: string): GridColType {
  const column = columns.find((col) => col.field === field);
  if (!column) throw new Error(`Unknown column: ${field}`);
  return column.type ?? "string";
}

export function elasticQueryStringToFilterModel(
  columns: DataGridProProps["columns"],
  queryString: string,
): GridFilterModel {
  const matches = queryString.matchAll(/(\w+):("[^"]+"|\([^)]+\)|(?:\S+(?:\\\s)*\S+?)+|\S+)/g);
  const filters = Array.from(matches).map((match) => {
    const [, field, value] = match;
    const camelField = camelCase(field);
    const columnType = getFieldColumnType(columns, camelField);
    const [operator, valueWithoutOperator] = getOperatorAndValue(value, columnType);
    return {
      columnField: camelField,
      operatorValue: operator,
      value: valueWithoutOperator,
      id: `${camelField}-${valueWithoutOperator}`,
    };
  });
  const linkOperator = queryString.replaceAll(/(\w+):("[^"]+"|\([^)]+\)|\S+)/g, "").includes("OR")
    ? GridLinkOperator.Or
    : GridLinkOperator.And;
  return { items: filters, linkOperator: linkOperator };
}
