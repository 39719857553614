import * as React from "react";
import { useMemo, useState } from "react";
import useSWR from "swr";
import { ServerErrorOverlay } from "components/DataGrid/components/ServerErrorOverlay";
import { serializeSortModel } from "components/DataGrid/helpers/serializers";
import { LabelDisplayedRowsArgs } from "@mui/material";
import { fetcher } from "services/api.service";
import { filterModelToElasticQueryString } from "components/DataGrid/helpers/elasticSerializers";
import { RevivnDataGrid, RevivnDataGridProps } from "components/DataGrid/RevivnDataGrid";
import { useEventListener } from "hooks/useEventListener";
import { NoRowsElasticOverlay } from "components/DataGrid/components/NoRowsElasticOverlay";
import { useElasticDataGridState } from "components/DataGrid/hooks/useElasticDataGridState";

export interface ElasticDataGridProps {
  syncStateWithSearchParams?: boolean;
  defaultToMostRecentParams?: boolean;
  url: string;
  updateRowUrl?: (id: string) => string;
}

interface Hit<T> {
  Id: string;
  Index: string;
  Source: Record<string, T>;
}

export interface ElasticSearchResult<T> {
  hits: {
    total: { value: number; relation: string };
    hits: Hit<T>[];
  };
}

const MAX_RESULTS = 10000;

// Updates the Pagination label to show a + at the end if the count is greater than MAX_RESULTS
const ElasticAwarePaginationLabel = (props: LabelDisplayedRowsArgs) => {
  const { from, to, count } = props;
  return `${from}-${to} of ${count <= MAX_RESULTS ? count : `${MAX_RESULTS}+`}`;
};

export const ElasticDataGrid = ({
  syncStateWithSearchParams,
  defaultToMostRecentParams,
  url,
  ...props
}: ElasticDataGridProps & Omit<RevivnDataGridProps, "rows">) => {
  const [gridState, setGridState] = useElasticDataGridState(
    props.columns,
    syncStateWithSearchParams,
    defaultToMostRecentParams,
  );
  const [bulkEditMode, setBulkEditMode] = useState(false);
  useEventListener("bulkEditStart", () => setBulkEditMode(true));
  useEventListener("bulkEditStop", () => setBulkEditMode(false));

  const { data, isLoading, error, mutate } = useSWR<ElasticSearchResult<unknown>>(
    () => {
      const { page, pageSize, filterModel, sortModel } = gridState;
      const query = filterModelToElasticQueryString(filterModel) || "";
      const sort = serializeSortModel(sortModel) || "";
      const from = (page * pageSize).toString();
      const size = pageSize.toString();
      const q = query.length === 0 ? "*" : query;

      const params = new URLSearchParams({ q, from, size, sort });
      return `${url}?${params}`;
    },
    fetcher,
    {
      keepPreviousData: true,
      revalidateOnFocus: !bulkEditMode,
      revalidateOnReconnect: !bulkEditMode,
      revalidateIfStale: !bulkEditMode,
    },
  );

  const rows = useMemo(() => (data ? data.hits.hits.map(({ Source }) => ({ ...Source })) : []), [data]);
  const NoRowsOverlay = error ? ServerErrorOverlay : NoRowsElasticOverlay;
  const totalCount = useMemo(() => {
    if (data?.hits.total) {
      switch (data.hits.total.relation) {
        case "eq":
          return data.hits.total.value;
        case "gte":
          return data.hits.total.value + 1;
        default:
          return 0;
      }
    }
    return data?.hits.total.value || 0;
  }, [data]);

  return (
    <RevivnDataGrid
      disableSelectionOnClick={true}
      {...props}
      rows={rows}
      rowCount={totalCount}
      loading={isLoading}
      pagination={true}
      page={gridState.page}
      pageSize={gridState.pageSize}
      paginationMode="server"
      onPageChange={(newPage) => setGridState((p) => ({ ...p, page: newPage }))}
      onPageSizeChange={(newPageSize) => {
        const currentItem = gridState.page * gridState.pageSize;
        const nextPage = Math.floor(currentItem / newPageSize);
        // https://github.com/mui/mui-x/issues/3516
        // Updating the page to keep the same records viewable results in unintentional jumping due to the
        // internal pagination model being inconsistent in MUI.  To fix, we'll need to upgrade MUI.
        setGridState((p) => ({ ...p, pageSize: newPageSize, page: nextPage }));
      }}
      rowsPerPageOptions={[5, 25, 50, 100, 200]}
      filterMode="server"
      filterModel={gridState.filterModel}
      onFilterModelChange={(model) => {
        setGridState((p) => ({ ...p, filterModel: model, page: 0 }));
      }}
      sortingMode="server"
      sortModel={gridState.sortModel}
      onSortModelChange={(model) => {
        setGridState((p) => ({ ...p, sortModel: model, page: 0 }));
      }}
      components={{ ...props.components, NoRowsOverlay }}
      componentsProps={{
        ...props.componentsProps,
        noRowsOverlay: { error },
        pagination: { labelDisplayedRows: ElasticAwarePaginationLabel },
      }}
      onBulkActionComplete={() => {
        mutate();
      }}
    />
  );
};
