import { GridRowId, GridRowModel, useGridApiContext } from "@mui/x-data-grid-pro";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import * as React from "react";
import { ReactNode } from "react";

export interface GridBulkActionsItemProps {
  icon?: ReactNode;
  label: string;
  eventName?: string;
  onClick?: (selectedRows: Map<GridRowId, GridRowModel>, event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
}

// GridBulkActionsItem is a component that represents a single item in the bulk actions menu.
// By default, the onClick handler dispatches a browser event that another component can listen for, as the
// menu is closed and unmounted immediately following the click!
//   The event will contain the selected rows as a detail property.
// (Keeping the menu open is challenging and causes z-index issues with other modal dialogs.)
export function GridBulkActionsItem(props: GridBulkActionsItemProps) {
  const apiRef = useGridApiContext();

  return (
    <MenuItem
      onClick={(event) => {
        const selectedRows = apiRef.current.getSelectedRows();
        props.onClick?.(selectedRows, event);
        if (!props.eventName) return;
        dispatchEvent(
          new CustomEvent(props.eventName, { detail: { selectedRows: Array.from(selectedRows.values()) } }),
        );
      }}
    >
      {props.icon && <ListItemIcon sx={{ "& > .MuiSvgIcon-root": { fontSize: 20 } }}>{props.icon}</ListItemIcon>}
      <ListItemText>{props.label}</ListItemText>
    </MenuItem>
  );
}
