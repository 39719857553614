import { Alert, Stack } from "@mui/material";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function ServerErrorOverlay({ error }: { error: any }) {
  const errorDetails = error?.response?.data?.error?.message;
  return (
    <Stack alignItems="center" direction="column" mt={2}>
      <Alert severity="error">
        {error.message}
        {errorDetails && <div>{errorDetails}</div>}
      </Alert>
    </Stack>
  );
}
