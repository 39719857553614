import {
  GridColTypeDef,
  GridEditInputCell,
  GridPreProcessEditCellProps,
  GridRenderEditCellParams,
} from "@mui/x-data-grid-pro";
import { Inventory } from "types/inventory";
import { AnySchema, ValidationError } from "yup";
import { Box, Popper } from "@mui/material";
import React, { useRef } from "react";

// We have to use MUI Popper here because GridEditInputCell doesn't forward refs properly for MUI Tooltip :(
const EditableCell = (cellParams: GridRenderEditCellParams<string>) => {
  const ref = useRef<HTMLDivElement | null>(null);
  return (
    <>
      <GridEditInputCell {...cellParams} />
      <Box ref={ref} sx={{ position: "absolute" }}></Box>
      <Popper open={cellParams.error} anchorEl={ref.current} placement={"bottom-start"}>
        <Box
          sx={{
            mt: 2,
            border: 1,
            p: 0.5,
            bgcolor: "error.main",
            color: "white",
            borderRadius: 1,
            borderColor: "error.main",
          }}
        >
          {cellParams.message}
        </Box>
      </Popper>
    </>
  );
};

// This function returns a GridColTypeDef object that can be used to create a column definition for a DataGrid column.
// Unfortunately, you have to pass the field name as there's no way to get it from the props passed to the preprocess method.
export const editableColDefWithValidation = function (validationSchema: AnySchema, field: string): GridColTypeDef {
  return {
    editable: true,
    preProcessEditCellProps: (cellParams: GridPreProcessEditCellProps<Inventory>) => {
      try {
        validationSchema.validateSync({ ...cellParams.row, [field]: cellParams.props.value });
        return { ...cellParams.props, error: false };
      } catch (error) {
        if (error instanceof ValidationError)
          return {
            ...cellParams.props,
            error: true,
            message: error.errors.join(", "),
          };
        return { ...cellParams.props, error: true };
      }
    },
    renderEditCell: (cellParams: GridRenderEditCellParams<string>) => {
      return <EditableCell {...cellParams} />;
    },
  };
};
