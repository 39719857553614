import { linkColDef } from "components/DataGrid/columnDefs/linkColDef";
import { IndexedInventory } from "types/inventory";
import { warehouseColDef } from "components/DataGrid/columnDefs/warehouseColDef";
import { singleSelectColDef } from "components/DataGrid/columnDefs/singleSelectColDef";
import { conditions } from "constants/conditions";
import { enrolledStatuses } from "constants/enrolled_statuses";
import { colors } from "constants/colors";
import { dateColDef } from "components/DataGrid/columnDefs/dateColDef";
import { deviceTypes } from "constants/device_types";
import { yearColDef } from "components/DataGrid/columnDefs/yearColDef";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { GridCellExpand } from "components/GridCellExpand";
import { inventoryEditValidation } from "validations/inventory";
import { editableColDefWithValidation } from "components/DataGrid/columnDefs/editableColDefWithValidation";

function renderCommentsCell(cellParams: GridRenderCellParams<string>) {
  return <GridCellExpand value={cellParams.row.comments || ""} width={cellParams.colDef.computedWidth} />;
}

export const columns = [
  {
    field: "label",
    headerName: "Label",
    width: 125,
    ...linkColDef<IndexedInventory>(({ row }) => `/pickups/${row.pickupId}/inventories/${row.id}`),
  },
  {
    field: "id",
    headerName: "ID",
    type: "number",
    width: 90,
    ...linkColDef<IndexedInventory>(({ row }) => `/pickups/${row.pickupId}/inventories/${row.id}`),
  },
  { field: "lineId", headerName: "Line ID", width: 125 },
  { field: "warehouseName", ...warehouseColDef },
  {
    field: "pickupId",
    headerName: "Pickup ID",
    width: 125,
    ...linkColDef<IndexedInventory>(({ row }) => `/pickups/${row.pickupId}`),
  },
  {
    field: "printed", // previously printedAt
    type: "boolean",
    headerName: "Printed",
    width: 125,
    sortable: false,
  },
  { field: "inventoryType", headerName: "Type", width: 125, ...singleSelectColDef(["internal", "partner"]) },
  { field: "inventoryImagesCount", headerName: "Image Count", width: 125, type: "number" },
  { field: "iqInventoryId", headerName: "IQ ID", width: 150 },
  { field: "iqPoIdLine", headerName: "IQ PO ID LINE", width: 150 },
  { field: "warehouseLocation", headerName: "Location", description: "Warehouse Location", width: 250, editable: true },
  { field: "manifestId", headerName: "Manifest ID", width: 250 },
  { field: "manufacturer", headerName: "Manufacturer", width: 125 },
  { field: "deviceType", headerName: "Device Type", width: 150, ...singleSelectColDef(deviceTypes) },
  {
    field: "serialNumber",
    headerName: "Serial Number",
    width: 250,
    ...editableColDefWithValidation(inventoryEditValidation, "serialNumber"),
  },
  { field: "model", headerName: "Model", width: 200, editable: true },
  { field: "sku", headerName: "SKU", width: 150 },
  { field: "condition", headerName: "Condition", width: 125, ...singleSelectColDef(conditions) },
  { field: "batteryCount", headerName: "Battery Cycles", width: 125, type: "number" },
  { field: "batteryHealth", headerName: "Battery Health", width: 125 },
  { field: "inventoryGroup", headerName: "Inventory Group", width: 125 },
  { field: "state", headerName: "State", width: 150 },
  { field: "enrolledStatus", headerName: "Enrolled", width: 150, ...singleSelectColDef(enrolledStatuses) },
  { field: "enrolledOrganization", headerName: "Enrolled Org", width: 175 },
  { field: "drive1ErasureStatus", headerName: "Data Wipe", width: 125 },
  { field: "securityLevel", width: 125 },
  { field: "year", ...yearColDef },
  { field: "quantity", headerName: "Quantity", width: 100, type: "number" },
  { field: "listed", headerName: "Listed As", width: 150 },
  { field: "color", headerName: "Color", width: 150, ...singleSelectColDef(colors) },
  { field: "ram", headerName: "RAM", width: 150 },
  { field: "cpu", headerName: "CPU", width: 250 },
  { field: "gpu", headerName: "GPU", width: 250 },
  { field: "hardDrive", headerName: "Hard Drive", width: 150 },
  { field: "hardDriveSizeInGb", headerName: "Drive Size (GB)", type: "number", width: 150 },
  { field: "comments", headerName: "Comments", width: 300, renderCell: renderCommentsCell, sortable: false },
  // TODO fetch server tag names? Or index all tag values?
  { field: "tagNames", headerName: "Tags", width: 250, sortable: false },
  // TODO editable
  { field: "assetTag", headerName: "Asset Tag", width: 250 },
  { field: "createdAt", headerName: "Created At", width: 200, ...dateColDef },
];
