import { useNavigate, useSearchParams } from "react-router-dom";
import * as React from "react";
import { useEffect, useState } from "react";
import { serializeSortModel } from "components/DataGrid/helpers/serializers";
import { filterModelToElasticQueryString } from "components/DataGrid/helpers/elasticSerializers";
import { GridFilterModel, GridSortModel } from "@mui/x-data-grid";
import {
  elasticQueryStringToFilterModel,
  elasticSortStringToSortModel,
} from "components/DataGrid/helpers/elasticDeserializers";
import { DataGridProProps } from "@mui/x-data-grid-pro/models/dataGridProProps";

interface ElasticQueryParams {
  page: number;
  size: number;
  query: string;
  sort: string;
}

interface ElasticGridState {
  filterModel: GridFilterModel;
  sortModel: GridSortModel;
  page: number;
  pageSize: number;
}

const DEFAULT_PARAMS: ElasticQueryParams = { page: 1, size: 25, query: "", sort: "" };

// Here we make an assumption that if you are syncing with the URL, there is only one datagrid per page.
// Thus, you don't have to specify some sort of persistence key, as it uses the pathname of the page.
// This may not end up being true in the long term, so if this assumption doesn't hold, maybe add a key prop?
const getDatagridParamsPersistenceKey = () => `dataGrid@${window.location.pathname}.lastParams`;

function persistMostRecentParams(params: ElasticQueryParams) {
  localStorage.setItem(getDatagridParamsPersistenceKey(), JSON.stringify(params));
}

const getMostRecentParams = (): Partial<ElasticQueryParams> | null => {
  const key = getDatagridParamsPersistenceKey();
  const params = JSON.parse(localStorage.getItem(key) || "{}") as ElasticQueryParams;
  return Object.values(params).every((value) => value === null) ? null : params;
};

const searchParamsToElasticQueryParams = (searchParams: URLSearchParams): Partial<ElasticQueryParams> => {
  const page = searchParams.get("page") ? Number(searchParams.get("page")) : undefined;
  const size = searchParams.get("size") ? Number(searchParams.get("size")) : undefined;
  const query = searchParams.get("q") || undefined;
  const sort = searchParams.get("sort") || undefined;
  return { page, size, query, sort };
};

function getInitialSearchParams(): Partial<ElasticQueryParams> | null {
  const params = searchParamsToElasticQueryParams(new URLSearchParams(window.location.search));
  return Object.values(params).some((value) => value !== undefined) ? params : null;
}

function fillInParamsWithDefaults(params?: Partial<ElasticQueryParams> | null | false): ElasticQueryParams {
  if (!params) return DEFAULT_PARAMS;
  return {
    page: params?.page || DEFAULT_PARAMS.page,
    size: params?.size || DEFAULT_PARAMS.size,
    query: params?.query || DEFAULT_PARAMS.query,
    sort: params?.sort || DEFAULT_PARAMS.sort,
  };
}

function elasticQueryParamsToGridState(
  columns: DataGridProProps["columns"],
  params: ElasticQueryParams,
): ElasticGridState {
  return {
    page: params.page - 1,
    pageSize: params.size,
    filterModel: elasticQueryStringToFilterModel(columns, params.query),
    sortModel: elasticSortStringToSortModel(params.sort),
  };
}

// Returns the controllable state for the datagrid, initialized from the url or local storage.
// This hook is also responsible for syncing the datagrid params with the URL search params and persisting
// the most recent params to local storage.
export function useElasticDataGridState(
  columns: DataGridProProps["columns"],
  syncStateWithSearchParams?: boolean,
  defaultToMostRecentParams?: boolean,
) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // These two refs help prevent infinite loops when syncing the state with the URL.
  // We start with them set to true to ignore the first render.
  const isInternalNavigationRef = React.useRef(true);
  const isExternalNavigationRef = React.useRef(true);
  const [gridState, setGridState] = useState<ElasticGridState>(() => {
    const queryParamSources = [
      syncStateWithSearchParams && getInitialSearchParams(),
      defaultToMostRecentParams && getMostRecentParams(),
    ];
    const initialQueryParams = fillInParamsWithDefaults(queryParamSources.find(Boolean));
    return elasticQueryParamsToGridState(columns, initialQueryParams);
  });

  // ---------------
  // Internal Navigation: When the datagrid state changes, update the URL search params.
  // We can't use setSearchParams here because it is not referentially stable and will change on every render
  // https://github.com/remix-run/react-router/issues/9991
  useEffect(() => {
    if (isExternalNavigationRef.current) {
      isExternalNavigationRef.current = false;
      return;
    }
    const { page, pageSize, filterModel, sortModel } = gridState;
    const query = filterModelToElasticQueryString(filterModel);
    const sort = serializeSortModel(sortModel);

    const newSearchParams = new URLSearchParams();
    if (query !== "") newSearchParams.set("q", query);
    if (sort !== "") newSearchParams.set("sort", sort);
    if (pageSize !== 25) newSearchParams.set("size", gridState.pageSize.toString());
    if (page !== 0) newSearchParams.set("page", (gridState.page + 1).toString());

    if (defaultToMostRecentParams) persistMostRecentParams({ query, sort, size: pageSize, page });
    if (!syncStateWithSearchParams) return;
    // We set this ref to prevent the effect above from running after navigation to prevent
    // an infinite loop of setting state and navigating.
    isInternalNavigationRef.current = true;
    navigate(`?${newSearchParams}`);
  }, [defaultToMostRecentParams, gridState, navigate, syncStateWithSearchParams]);

  // ---------------
  // External Navigation: When the URL search params change, update the datagrid state.
  useEffect(() => {
    // If the search params changed due to our own navigation, don't update the state.
    if (isInternalNavigationRef.current) {
      isInternalNavigationRef.current = false;
      return;
    }
    if (!syncStateWithSearchParams) return;

    isExternalNavigationRef.current = true;
    const queryParams = searchParamsToElasticQueryParams(searchParams);
    const nextState = elasticQueryParamsToGridState(columns, fillInParamsWithDefaults(queryParams));
    setGridState(nextState);
  }, [columns, searchParams, syncStateWithSearchParams]);

  return [gridState, setGridState] as const;
}
