import * as React from "react";
import { ReactElement } from "react";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarProps,
} from "@mui/x-data-grid-pro";
import { GridBulkEditToolbarButtons } from "components/DataGrid/components/GridBulkEditToolbarButtons";
import { GridBulkActionsButton } from "components/DataGrid/components/GridBulkActionsButton";
import { BulkEditChanges } from "components/DataGrid/RevivnDataGrid";

export const dispatchBulkActionComplete = () => {
  const event = new CustomEvent("bulkActionComplete");
  window.dispatchEvent(event);
};

export const dispatchToolbarEvent = (eventName: string) => {
  const event = new CustomEvent(eventName);
  window.dispatchEvent(event);
};

export interface RevivnToolbarProps extends GridToolbarProps {
  getBulkActions?: () => ReactElement[];
  onBulkActionComplete?: () => void;
  checkboxSelection?: boolean;
  bulkEditable?: boolean;
  bulkEditMode?: boolean;
  bulkEditChanges?: BulkEditChanges;
  processBulkUpdate?: (changes: BulkEditChanges) => Promise<void>;
}

export const RevivnToolbar = (props: RevivnToolbarProps) => {
  return (
    <GridToolbarContainer sx={props.sx}>
      {props.checkboxSelection && <GridBulkActionsButton {...props} />}
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport {...props} />
      <GridBulkEditToolbarButtons {...props} />
    </GridToolbarContainer>
  );
};
