import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { api } from "services/api.service";
import TokenService from "services/token.service";

type AuthContextValue = {
  loggedIn: boolean;
  setLoggedIn: (loggedIn: boolean) => void;
};
type Tokens = {
  accessToken: string;
  client: string;
  uid: string;
  expiry: string;
};
export const AuthContext = createContext<AuthContextValue>({
  loggedIn: false,
  setLoggedIn: () => {},
});

function setTokens(tokens: Tokens) {
  TokenService.set("accessToken", tokens.accessToken);
  TokenService.set("client", tokens.client);
  TokenService.set("uid", tokens.uid);
  TokenService.set("expiry", tokens.expiry);
}

function getTokensFromUrl(search: string): Tokens {
  const params = new URLSearchParams(search);

  const accessToken = params.get("access-token") || "";
  const client = params.get("client") || "";
  const uid = params.get("uid") || "";
  const expiry = params.get("expiry") || "";

  return {
    accessToken,
    client,
    uid,
    expiry,
  };
}

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [loggedIn, setLoggedIn] = useState<boolean>(Boolean(TokenService.get("accessToken")));
  const search = window.location.search;

  const value = useMemo(
    () => ({
      loggedIn,
      setLoggedIn,
    }),
    [loggedIn],
  );

  useEffect(() => {
    if (search.includes("access-token")) {
      const urlTokens = getTokensFromUrl(search);
      setTokens(urlTokens);

      setLoggedIn(true);
    }
  }, [loggedIn, search]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default function useAuth() {
  const { loggedIn, setLoggedIn } = useContext(AuthContext);

  const login = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      const response = await api.post("/auth/sign_in", { email, password });

      TokenService.set("accessToken", response.headers["access-token"]);
      TokenService.set("client", response.headers.client);
      TokenService.set("uid", response.headers.uid);
      TokenService.set("expiry", response.headers.expiry);

      setLoggedIn(true);
    },
    [setLoggedIn],
  );

  return {
    login,
    loggedIn,
  };
}
