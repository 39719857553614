import ApplicationBreadCrumbs from "components/ApplicationBreadCrumbs";
import InventoriesDataGrid from "components/Inventories/InventoriesDataGrid";
import { Route, Routes } from "react-router-dom";
import { Crumb } from "types/crumb";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { ElasticDataGrid } from "components/DataGrid/RevivnElasticDataGrid";
import { columns } from "components/Inventories/DataGrid/datagridColumns";
import { Box, Paper } from "@mui/material";
import React from "react";
import { BulkActions } from "components/Inventories/DataGrid/BulkActions";
import { GridBulkPrintActionItem } from "components/Inventories/DataGrid/GridBulkPrintActionItem";

const IndexElement = ({ crumbs }: { crumbs: Crumb[] }) => {
  const { enabled: elasticInventoryEnabled, isLoading } = useFeatureFlag("elastic_inventories");

  if (isLoading) return null;
  return (
    <>
      <ApplicationBreadCrumbs sx={{ mb: 1, mx: 0 }} crumbs={crumbs} />

      {elasticInventoryEnabled ? (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr",
            gap: 1,
            gridTemplateRows: "1fr",
            flexGrow: 1,
            minHeight: "600px",
          }}
        >
          <Paper>
            <BulkActions />
            <ElasticDataGrid
              autoHeight={false}
              checkboxSelection={true}
              columns={columns}
              density="compact"
              defaultToMostRecentParams
              syncStateWithSearchParams
              persistSettingsKey={"inventories"}
              url={"/inventories/elastic_index"}
              getBulkActions={() => [<GridBulkPrintActionItem />]}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    lineId: false,
                    pickupId: false,
                    printed: false,
                    inventoryImagesCount: false,
                    iqInventoryId: false,
                    iqPoIdLine: false,
                    assetTag: false,
                    inventoryType: false,
                  },
                },
              }}
            />
          </Paper>
        </Box>
      ) : (
        <InventoriesDataGrid
          fieldsToHide={[
            "lineId",
            "pickupId",
            "printedAt",
            "inventoryImages",
            "iqInventoryId",
            "iqPoIdLine",
            "assetTag",
          ]}
          density="compact"
        />
      )}
    </>
  );
};

export default function InventoriesPage() {
  const crumbs: Crumb[] = [{ name: "Inventories", path: "/inventories" }];

  return (
    <Routes>
      <Route index element={<IndexElement crumbs={crumbs} />} />
    </Routes>
  );
}
